export const petTrackStack = [
    {
        imageUrl: "images/php.png",
        height: "40",
        width: "65",
        name: "php"
    },
    {
        imageUrl: "images/laravel.png",
        height: "40",
        width: "40",
        name: "laravel"
    },
    {
        imageUrl: "images/mysql.png",
        height: "40",
        width: "70",
        name: "mySql"
    },
    // {
    //     imageUrl: "https://isoonph.me/imgs/skills/figma.svg",
    //     height: "40",
    //     width: "40",
    //     name: "figma"
    // },
]
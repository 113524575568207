export const ucarStack = [

    {
        imageUrl: "images/kotlin.png",
        height: "40",
        width: "40",
        name: "Kotlin"
    },
    {
        imageUrl: "images/androidMan.png",
        height: "40",
        width: "50",
        name: "android"
    },
    {
        imageUrl: "images/adobeXD.png",
        height: "40",
        width: "40",
        name: "adobeXD"
    },
]
export const pplottoStack = [

    {
        imageUrl: "https://isoonph.me/imgs/skills/nextjs.svg",
        height: "40",
        width: "75",
        name: "Nextjs"
    },
    {
        imageUrl: "images/vercel.png",
        height: "40",
        width: "40",
        name: "vercel"
    },
    {
        imageUrl: "images/antd.png",
        height: "40",
        width: "40",
        name: "antd"
    },
    {
        imageUrl: "images/meterial.png",
        height: "40",
        width: "40",
        name: "Meterial ui"
    },
    {
        imageUrl: "https://isoonph.me/imgs/skills/figma.svg",
        height: "40",
        width: "40",
        name: "figma"
    },
]
export const soizensunStack = [

    {
        imageUrl: "images/react.png",
        height: "40",
        width: "40",
        name: "Nextjs"
    },
    {
        imageUrl: "images/vercel.png",
        height: "40",
        width: "40",
        name: "vercel"
    },
    {
        imageUrl: "images/evergreen.png",
        height: "40",
        width: "40",
        name: "EverGreen"
    },
    {
        imageUrl: "images/styled.png",
        height: "40",
        width: "40",
        name: "StyledComponent"
    }
]